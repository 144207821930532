<template>
  <en-drawer :model-value="modelValue" title="单据历史" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" :loading="form.loading">
      <div class="flex justify-between">
        <span class="mt-2">{{ data?.preparedBy?.name }}</span>
        <span class="mt-2">{{ formatDate(data?.businessDatetime) }}</span>
      </div>
    </en-form>
    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudSettlementDefinitions['PayableDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      async handler() {
        this.form.init()
        if (this.modelValue) {
          this.form.data.id = this.data?.id
          if (this.form.data.id) this.form.get()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        data: {
          quickSearch: '',
          typeCode: '',
          documentEndDate: '',
          documentStartDate: '',
          endDate: '',
          startDate: '',
          preparedByName: '',
          statusCode: ['P']
        },
        rules: {
          creditDueDatetime: [{ required: true, message: '请选择账期日期' }]
        },
        ajax: {
          get: {
            action: 'GET /enocloud/settlement/payable/:payableId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          }
        },
        computed: {
          disabled() {
            // return this.form.data.status?.code === 'A' || this.form.data.status?.code === 'S'
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            this.emit('confirm')
          }
        }
      }
    }
  }
})
</script>
